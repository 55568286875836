import * as React from "react"
import HeaderBar  from "../components/HeaderBar"
import FooterBar from "../components/FooterBar"

const EducationPage = () => {
    return (
        <div>
            {HeaderBar()}
            <h4>Graduate:</h4>
            <p>Degree: Master of Science in Computer Science</p>
            <p>Expected Graduation: Never, One semester was enough</p>
            <ul>
                <li>GPA: 3.66 / 4.0</li>
            </ul>

            <h4>Undergraduate:</h4>
            <p>Degree: Bachelor of Science in Computer Science</p>
            <p>Graduated: May 2021</p>
            <ul>
                <li>GPA: 3.94 / 4.0</li>
                <li>Summa Cum Laude</li>
                <li>Business Minor</li>
                <li>Cybersecurity Minor</li>
                <li>Engineering Honors</li>
                <li>Undergraduate Research Scholar</li>
            </ul>

            <h4>Selected Courses:</h4>
            <ul>
                <li>CSCE 645 - Geometric Modeling <a href="/polypack">(Project Page)</a></li>
                <li>CSCE 491 - Undergraduate Research <a href="/urs">(Project Page)</a></li>
                <li>CSCE 463 - Networks and Distributed Processing</li>
                <li>CSCE 462 - Microcomputer Systems<a href="/cs462">(Project Page)</a></li>
                <li>CSCE 438 - Distributed Systems <a href="/tinysns">(Project Page)</a></li>
                <li>CSCE 435 - Parallel Computing</li>
                <li>CSCE 430 - Problem Solving Strategies <a href="/comppro">(Project Page)</a></li>
                <li>CSCE 420 - Artificial Intelligence</li>
                <li>CSCE 313H - Intro to Computer Systems <a href="/wikipedia">(Project Page)</a></li>
                <li>CSCE 221H - Data Structures and Algorithms <a href="/mergesort">(Project Page)</a></li>
            </ul>
            {FooterBar()}
        </div>
    )
}

export default EducationPage